<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'bg-blue-300',
  },
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <TnHeader />
    <img
      src="@/assets/cloud-left.svg"
      alt="cloud"
      class="absolute top-[250px] hidden h-[60px] md:block"
    />
    <img
      src="@/assets/cloud-right.svg"
      alt="cloud"
      class="absolute right-0 top-[500px] hidden h-[60px] md:block"
    />
    <div class="flex-1">
      <slot />
    </div>
    <TnFooter class="mt-auto" />
  </div>
</template>
